import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {VexRoutes} from "@vex/interfaces/vex-route.interface";
import {QuicklinkStrategy} from "ngx-quicklink";
import {AuthGuard} from "@core/guard/auth.guard";

const routes: VexRoutes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: '',
        component: CustomLayoutComponent,
        canActivate: [
            AuthGuard
        ],
        children: [
            {
                path: '',
                redirectTo: '/',
                pathMatch: 'full'
            },
            {
                path: 'activity-management',
                loadChildren: () => import('./pages/activity-management/activity-management.module').then(m => m.ActivityManagementModule),
                canActivate: [
                    AuthGuard
                ],

            },
            {
                path: 'social',
                loadChildren: () => import('./pages/social/social.module').then(m => m.SocialModule),
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'administration',
                loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule),
                canActivate: [
                    AuthGuard
                ],
            },]
    },
    {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
    },
    {
        path: 'Error',
        loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module)
    },

];
@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: QuicklinkStrategy,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected',
        anchorScrolling: 'enabled',
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
